import { AddError } from '../addError/addError';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { isSlotWithOpenWaitingList } from '../../../../utils/slotAvailability/slotAvailability';
import { checkForPricingPlanError } from '../../../../utils/pricingPlans/pricingPlans';
import { OnServiceVariantsOptionSelected } from '../onServiceVariantsOptionSelected/onServiceVariantsOptionSelected';
import { isServiceVariantWithStaff } from '../../../../utils/dynamicPricing/dynamicPricing';
import { Preference, TriggeredByOptions } from '../../../../types/types';
import { bookingsCalendarBookingDetailsLoad } from '@wix/bi-logger-wixboost-ugc/v2';
import { isSelectedTimeSlotWithWaitingListIndication } from '../../../../utils/timeSlots/timeSlots';
import { DO_NOT_CARE_STAFF } from '../../../../constants/constants';
import { canAutoAssignStaff } from '../../../../utils/serviceUtils/serviceUtils';

export type SetSelectedTime = (
  selectedTime: string | undefined,
  triggeredBy: TriggeredByOptions,
) => void;

export function createSetSelectedTimeAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  addError: AddError,
  onServiceVariantsOptionSelected: OnServiceVariantsOptionSelected,
): SetSelectedTime {
  return (selectedTime: string | undefined, triggeredBy) => {
    const [state, setState] = getControllerState();
    const {
      availableSlots,
      purchasedPricingPlans,
      rescheduleBookingDetails,
      serviceVariantsMap,
      servicesInView,
    } = state;
    const { biLogger, isDateAndTimeViewMode, experiments } = context;
    const service = servicesInView[0];
    const serviceVariants = serviceVariantsMap[service.id];

    setState({
      calendarErrors: [],
      selectedVariantsOptions: [],
    });

    let selectableSlots;
    if (selectedTime) {
      selectableSlots = availableSlots?.availabilityEntries?.filter(
        (availableSlot) => {
          return (
            availableSlot.slot?.startDate === selectedTime &&
            (availableSlot.bookable || isSlotWithOpenWaitingList(availableSlot))
          );
        },
      );

      const isRescheduleFlow = !!rescheduleBookingDetails;
      const pricingPlanError = checkForPricingPlanError({
        purchasedPricingPlans,
        isRescheduleFlow,
        selectedTime,
      });
      if (pricingPlanError) {
        addError(pricingPlanError);
      }

      if (
        !isDateAndTimeViewMode &&
        isServiceVariantWithStaff(serviceVariants) &&
        selectableSlots &&
        selectableSlots.length > 0
      ) {
        const firstSlotStaffId = selectableSlots[0].slot!.resource!.id!;
        if (
          selectableSlots.every(
            ({ slot }) => firstSlotStaffId === slot!.resource!.id,
          )
        ) {
          onServiceVariantsOptionSelected({
            optionId: serviceVariants?.options!.values![0].id!,
            choiceId: firstSlotStaffId,
            numberOfParticipants: 1,
            isMultipleChoices: false,
          });
        }
      }

      const isWaitingListFlow = isSelectedTimeSlotWithWaitingListIndication(
        availableSlots,
        selectedTime,
      );
      const waitListProperty = isWaitingListFlow ? { waitlist: true } : {};

      void biLogger.report(
        bookingsCalendarBookingDetailsLoad({
          triggeredBy,
          selectedSlot: selectedTime,
          properties: JSON.stringify({
            ...waitListProperty,
          }),
        }),
      );
    }

    const isAutoAssignStaffAllowed =
      experiments.enabled('specs.bookings.calendarAutoAssignResource') &&
      canAutoAssignStaff({
        ...state,
        selectableSlotsAtSelectedTime: selectableSlots,
      });

    const selectedBookingPreferences = isAutoAssignStaffAllowed
      ? [{ key: Preference.STAFF_MEMBER, value: DO_NOT_CARE_STAFF }]
      : [];

    setState({
      selectedBookingPreferences,
      selectableSlotsAtSelectedTime: selectableSlots ?? [],
      selectedTime,
    });
  };
}
